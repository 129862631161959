import { http } from 'services/http'
import { INotificationsResponse } from 'services/notifications/notifications.type'
import { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'

export const ApiPathsNotifications = {
  notifications: (filter: boolean = true) => `/notifications${!filter ? `?filter.isSeen=${filter}` : ''}`,
  markAllNotificationsAsSeen: '/notifications/markAllAsSeen',
  informCandidates: (countryId?: string, serviceId?: string) => {
    const queryArr: string[] = []
    if (serviceId) {
      queryArr.push(`serviceId=${serviceId}`)
    }
    if (countryId) {
      queryArr.push(`countryId=${countryId}`)
    }
    const query = queryArr.length > 0 ? `?${queryArr.join('&')}` : ''
    return `notifications/informCandidates${query}`
  },
  getAdvertisedServices: ({ page = 1, limit = 1000 }: { page?: number; limit?: number }) =>
    `services?page=${page}&limit=${limit}`,
}

export const useGetAllNotifications = (filter: boolean, config: SWRConfiguration<any> = {}) => {
  const { data, error, mutate } = useSWRImmutable(
    ApiPathsNotifications.notifications(filter),
    getNotificationsResource<INotificationsResponse>,
    {
      refreshInterval: 15 * 60 * 1000,
      ...config,
    },
  )

  return {
    data,
    error,
    mutate,
    isLoading: !data && !error,
  }
}

export const useUpdateNotification = (config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsNotifications.notifications(), patchNotification, {
    ...config,
  })

  return { trigger, isMutating }
}
export const sendNotification = async (url: string, { arg }: { arg: any }): Promise<any> => {
  const { data } = await http.post(url, arg)

  return data
}

export const useSendGlobalNotification = (
  config: SWRMutationConfiguration<any, any, any, string>,
  countryId?: string,
  serviceId?: string,
) => {
  const { trigger, isMutating } = useSWRMutation(
    ApiPathsNotifications.informCandidates(countryId, serviceId),
    (url, { arg }) => sendNotification(url, { arg }),
    { ...config },
  )

  return { trigger, isMutating }
}

export const useMarkAllNotificationsAsRead = (config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(
    ApiPathsNotifications.markAllNotificationsAsSeen,
    markAllNotifications,
    {
      ...config,
    },
  )

  return { trigger, isMutating }
}

export const getNotificationsResource = async <T>(path: string): Promise<T> => {
  const { data } = await http.get(path)

  return data
}

export const patchNotification = async (url: string, { arg }: { arg: string }): Promise<any> => {
  const { data } = await http.patch(`${url}/${arg}`, { isSeen: true })

  return data
}

export const markAllNotifications = async (url: string) => {
  const { data } = await http.post(url)

  return data
}
